<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <WindowTitleBar title="Jobwork Receipt Return" @loadEmployees="loadEmployees" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Jobworker</label>
              <select id="cmbJobworker" ref="cmbJobworker" class="form-control select" required="" v-if="voucher"  v-model="voucher.jobworker_id" @change="loadOpenLots_4_jobworker" >
                <option v-for="ledger in jobworkers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material ">
              <label class="control-label font-weight-semibold">Shift</label>
              <select id="cmbShift" class="form-control" autofocus="" v-if="voucher"  v-model="voucher.shift_id" >
                <option value="2" selected>Day</option>
                <option value="3">Night</option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class=" control-label font-weight-semibold">Lot No</label>
              <select ref="el_workorder" class="form-control"  v-if="voucher" v-model="voucher.lot_id" @change="loadLot" >
                <option v-for="order in lots" v-bind:value="order.id">
                  {{order.doc_no}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Date</label>
              <input type="date" class="form-control text-right" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-md-12">

            <!-- Static mode -->
            <div class="card">
              <div class="card-header header-elements-inline">
                <h5 class="card-title">Fabric Information</h5>
                <div class="header-elements">

                </div>
              </div>

              <div class="card-body">


                <div class="form-group row">

                  <label class="col-form-label col-md-1">Quality</label>
                  <div class="col-md-3">
                    <select class="form-control" v-if="voucher" v-model="quality_id" @change="fill_colors">
                      <option v-for="itm in qualities" v-bind:value="itm.id">
                        {{ itm.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1">Color</label>
                  <div class="col-md-3">
                    <select class="form-control" v-if="voucher" v-model="color_id"  >
                      <option v-for="color in colors" v-bind:value="color.id">
                        {{ color.name }}
                      </option>
                    </select>
                  </div>



                  <label class="col-form-label col-md-1">Mtr</label>
                  <div class="col-md-1">
                    <input id="txtqty" type="number" class="form-control text-right" placeholder="Enter Mtr" min="0" required  v-if="voucher" v-model="voucher.qty">
                  </div>


                  <label class="col-form-label col-md-1">Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter Weight" min="0" required  v-if="voucher" v-model="voucher.weight">
                  </div>


                </div>


              </div>
            </div>
            <!-- /static mode -->

          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Remarks</label>
              <textarea id="txtremarks" class="form-control" placeholder="Remarks"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-6">

          </div>

          <div class="col-md-3 text-right">

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="perform_save_action" >Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="perform_clear_action" >Clear</button>
          </div>
        </div>
      </div>

      <FocusTrap>
        <vmodal name="viewer-window" transition="nice-modal-fade" :resizable="true" width="80%" height="500" @opened="afterOpen" @closed="clear" >
        <div id="viewer-host">
        </div>
      </vmodal>
      </FocusTrap>

      <!-- Print Preview -->
      <!-- modal -->
      <FocusTrap>
        <div class="modal fade" id="fsPrintPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <ReportViewer v-if="canprint" v-bind:reportfilename="filename" v-bind:json_data = "voucher"  v-bind:report_url = "reppath" :show_page_in_full_width="false" />
            </div>
          </div>
        </div>
      </FocusTrap>
      <!-- modal -->
      <!-- Print Preview -->

  </div>
  </FocusTrap>
</template>

<script>
import ReportViewer from "@/views/tools/ReportPrinterByJson";
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'JobworkReceiptReturnForm',
  store,
  components: {
    WindowTitleBar,
    ReportViewer,
  },
  props: {
    mytrans: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}')
    }
  },
  beforeMount () {
    this.voucher = this.mytrans;
  },
  data() {
    return {
      defects:[],
      emps:[],
      jobworkers:[],
      lots : [],
      colors: [],
      qualities :[],
      lot : JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"0001-01-01","workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":0,"type":0,"name":"","alias":"","group":{"id":0,"status":"Active","type":0,"name":"","alias":""}},"print_data":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"remarks":"","pcs":0,"wgt":0,"jobworker_waste":0,"company_waste":0,"list":[]}'),
      voucher : JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}'),
      mycard: {},
      quality_id :0,
      color_id : 0,
      isActive: false,
      filename: 'jobwork_receipt_return.mrt',
      canprint: false,
      print_data: JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}'),
    }
  },
  created(){
  },
  mounted(){
    const self = this;
    self.$data.mycard = $('#mycard');

    if (self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }
    self.loadJobworkers();
    self.loadEmployees();

    $('#cmbJobworker').focus();
  },
  filters:{
    weightFormat (val){
      return val.toFixed(3);
    }
  },
  methods:{
    closeThis(){
      this.$emit('jobworkreceiptreturn_window_closed');
      if(this.voucher.id > 1){

        this.voucher = JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}');
      }else{
        this.$router.push("/")
      }
    },
    setTitle() {
      return this.voucher.id == 0 ? "Jobwork Receipt Return Creation" : "Doc No: " + this.voucher.doc_no + "  Jobwork Receipt Return Updation";
    },
    loadJobworkers() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      block_el('#mycard');

      //fetch the Ledgers
      self.$data.jobworkers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        $('#cmbJobworker').unblock();
        if (resp.ok){
          if(resp.data != null){
            self.$data.jobworkers = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      }).finally(function () {
        unblock_el('#mycard');
      });

    },
    loadEmployees(){

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      block_el('#mycard');
      self.$data.emps = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/hr/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(  _.isArray(resp.data)){
            self.$data.emps = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:1500});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error", timer:1500});
      }).finally(()=>{
        unblock_el('#mycard');
      });
    },
    loadColors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      block_el('#mycard');

      self.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colors/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)) {
            self.colors = resp.data;
          }
        } else {
          swal ( { title: "Oops!" , text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        // unblock_el('#mycard');
      });

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)) {
            self.qualities = resp.data;
          }
        } else {
          swal ( { title: "Oops!" , text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        unblock_el('#mycard');
      });


    },
    loadOpenLots_4_jobworker(jobworker_id){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.lots = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/lotreceipts/jobworker/${self.$data.voucher.jobworker_id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){

          if(  _.isArray(resp.data)){
            self.$data.lots = resp.data;
          }

        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:3000});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes" ,  text: err.toString(),  type: "error", timer:3000});
      });

    },
    loadOpenLots(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      self.$data.lots = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/lotreceipts/state/Active`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){

          if(  _.isArray(resp.data)){
            self.$data.lots = resp.data;
          }

        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:3000});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes" ,  text: err.toString(),  type: "error", timer:3000});
      });

    },
    loadLot(){
      const self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      block_el('#mycard');

      self.lot = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"0001-01-01","workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":0,"type":0,"name":"","alias":"","group":{"id":0,"status":"Active","type":0,"name":"","alias":""}},"print_data":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"remarks":"","pcs":0,"wgt":0,"jobworker_waste":0,"company_waste":0,"list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/lotreceipt/${self.voucher.lot_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.lot = resp.data;
            self.fill_qualities();
            // self.$data.lot.doc_date = moment(self.$data.lot.doc_date).format('YYYY-MM-DD');
            // $('#rolling_window').modal('show')
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }

        // $('#mycard').unblock();
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });

      }).finally( ()=>{
        unblock_el('#mycard');
      });

    },
    fill_qualities(){
      const self = this;
      try{
        self.$data.qualities = [];
        self.$data.colors = [];
        self.lot.list.forEach((detail)=>{
          self.$data.qualities.push(detail.quality);
        });
      }catch (e) {
        alert(e);
      }
    },
    fill_colors(){
      const self = this;
      try{
        self.$data.colors = [];
        self.lot.list.forEach((detail)=>{
          if(detail.quality.id == self.$data.quality_id) {
            self.$data.colors.push(detail.color);
          }
        });
      }catch (e) {
        alert(e);
      }
    },
    perform_save_action(){
      const self = this;

      try {
        self.$data.reppath = '';

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));

        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.status = "Active";
        myvoucher.cmp_id = self.$store.state.user.company.id;

        myvoucher.qty = parseFloat(myvoucher.qty);
        myvoucher.weight = parseFloat(myvoucher.weight);
        myvoucher.quality_id = parseInt(self.quality_id);
        myvoucher.color_id = parseInt(self.color_id);

        if (myvoucher.shift_id < 1) {
          alert("Invalid Shift");
          return
        } else if (myvoucher.jobworker_id < 1) {
          alert("Invalid Jobworker");
          return
        } else if (myvoucher.lot_id < 1) {
          alert("Invalid LOT No");
          return
        } else if (myvoucher.qty < 1) {
          alert("Invalid Meter");
          return
        } else if (myvoucher.weight < 1) {
          alert("Invalid Weight");
          return
        }

        const requestOptions = {
          method: (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode: "cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        block_el('#mycard');

        self.canprint = false;
        self.$data.print_data = JSON.parse('{"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"other_damages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/receiptreturn`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$data.voucher =   JSON.parse(JSON.stringify(resp.data));
            // swal({
            //   title: "Success!", text: resp.msg, type: "success", timer: 3000, onClose: () => {
            //     $('#txtqty').focus();
            //   }
            // });

            self.$emit('jobwork_receiptreturn_saved', resp.data);

            if (confirm("Do You want Print?")) {
              self.printPreview( resp );
              $('#txtqty').focus();
            }

            self.$data.voucher.id = 0;
            self.$data.voucher.qty = 0;
            self.$data.voucher.weight = 0.0;
            self.setTitle();

          } else {
            swal({
              title: "Oops!", text: resp.msg, type: "error", timer: 3000, onClose: () => {
                $('#txtqty').focus();
              }
            });
          }

        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error", timer: 3000 });
        }).finally(function () {
          unblock_el('#mycard');
        });
      }catch (e) {
        alert(e);
      }
    },
    perform_clear_action(){
      const self = this;

      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}');
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

      $('#cmbShift').focus();

    },
    printPreview (resp) {
      const self = this;

      console.log(JSON.stringify(resp));

      let id = resp.data.id;

      self.$data.filename = 'jobwork_receipt_return.mrt';
      self.$data.voucher = resp;
      self.$data.reppath =  `${process.env.VUE_APP_ROOT_API}api/jobwork/receiptreturn/${id}`;
      self.$data.canprint = true;
      $('#fsPrintPreview').modal('show');

      setTimeout(() => {
        $('#fsPrintPreview').modal('hide');
      }, 900);
    },
  }
}
</script>

<style scoped>
  input:focus {
  background: #feff00;
  }

  textarea:focus {
  background: #feff00;
  }

  select:focus {
  background: #feff00;
  }

  button:focus {
  background: #feff00;
  color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  /* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }

  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }

  #viewer-host {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
</style>
